<template>
  <div>
    {{ cdate }}
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: "cellCdate",
    props: ["rowIndex", "prop", "data"],

    computed: {
      cdate () {
        return moment(this.data[this.rowIndex][this.prop]).format('DD.MM.YYYY')
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>